export  const config={
    // Development
    // API_URL : 'https://api.restro360.com',
    // IMG_UPLOAD_URL : 'https://api.restro360.com',
    // GA_MeasurementID: 'G-1V4BWYRHXR', // restro
    // CLIENT: "Restro",
    // Pusher_Key: '4810430304445c739ba3',
    // STATUS:"",

    // Production Potful
    // CLIENT: "Potful",
    // API_URL : 'https://api.restro.app',
    // IMG_UPLOAD_URL : 'https://admin.restro.app',
    // GA_MeasurementID: 'G-1V4BWYRHXR', // potful,
    // Pusher_Key: 'e9085511697be3d83773',
    // STATUS:"d-none",

    // Production Retro in Metro
    CLIENT: "Restro",
    API_URL : 'https://api-001.restro.app',
    IMG_UPLOAD_URL : 'https://admin-001.restro.app',
        GA_MeasurementID: 'G-QNBDC681JE', // Retro in Metro,
    Pusher_Key: '94805154c54a5c606d19',
    STATUS:"d-none",
}
// AIzaSyDbGyWeFsL0XanjbAA8TghXMFuAiBcuQmA
